<template>
  <router-link :to="to">
    <div class="d-flex align-center">
      <v-img
        :alt="alt"
        height="36px"
        :class="`${betaTag ? 'beta-tag' : ''}`"
        src="/Icon CC_thin_color.svg"
        contain
      />
    </div>
  </router-link>
</template>

<script>
import { META_IMAGE_ALT } from "@/constants/config";

export default {
  name: "AppLogo",
  props: {
    to: {
      type: String,
      default: "/"
    },
    type: {
      type: String,
      default: "blue"
    },
    height: {
      type: String,
      default: "36px"
    },
    betaTag: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    alt() {
      return META_IMAGE_ALT;
    }
  }
};
</script>
<style scoped>
.beta-tag {
  overflow: visible;
}
.beta-tag:after {
  content: "beta";
  position: absolute;
  top: 0px;
  right: -14px;
  border-radius: 36px;
  color: white;
  z-index: 99;
  font-size: 0.65rem;
  line-height: 0.85rem;
  padding: 1px 11px;
  display: flex;
  justify-content: center;
  text-align: center;
  text-indent: 0;
  background-color: var(--v-primary-base);
  height: auto;
  width: auto;
}
</style>
