<template>
  <v-app>
    <NavigationBar />

    <v-main class="pa-0">
      <router-view />
    </v-main>

    <FooterBar />
  </v-app>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import FooterBar from "@/components/FooterBar";
import { WEB_TITLE } from "@/constants/config";
import i18n from "@/i18n";

export default {
  name: "App",
  metaInfo: {
    title: WEB_TITLE,
    titleTemplate: `${WEB_TITLE} | %s`,
    htmlAttrs: {
      lang: i18n.locale
    }
  },
  components: {
    FooterBar,
    NavigationBar
  },
  data() {
    return {};
  }
};
</script>

<style>
@import "./assets/main.scss";
</style>
