<template>
  <v-menu offset-y open-on-click>
    <template #activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>mdi-earth</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item-group v-model="selectedLanguage" color="indigo">
        <v-list-item
          v-for="(locale, i) in $i18n.availableLocales"
          :key="i"
          @click="changeLocale(locale)"
        >
          <v-list-item-title>{{ locale }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "LocaleSwitch",
  data() {
    return {
      selectedLanguage: this.$i18n.locale
    };
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("locale", locale);
      this.$router.go();
    }
  }
};
</script>

<style scoped></style>
