<template>
  <v-footer color="primary lighten-1" padless>
    <v-row justify="center" no-gutters>
      <v-col
        cols="12"
        class="d-flex flex-row justify-center flex-wrap"
        justify="center"
      >
        <v-btn
          v-for="(link, index) in links"
          :key="index"
          color="white"
          text
          tile
          exact
          :to="link.path"
          class="my-2"
        >
          {{ link.name }}
        </v-btn>
      </v-col>

      <v-col class="py-4 text-center white--text" cols="12">
        <v-container>
          {{ new Date().getFullYear() }} — <strong>ChartCrafter</strong>
          <div class="d-inline p-absolute b-3 r-0 additional-links">
            <v-btn
              v-for="(link, index) in additionalLinks"
              :key="index"
              color="white"
              text
              tile
              exact
              :to="link.path"
              class="my-2"
            >
              {{ link.name }}
            </v-btn>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "FooterBar",
  data() {
    return {
      links: [
        { name: this.$t("sections.home"), path: "/" },
        { name: this.$t("sections.patterns"), path: "/patterns" },
        { name: this.$t("sections.editor"), path: "/editor" },
        { name: this.$t("sections.team"), path: "/team" },
        { name: this.$t("sections.contact"), path: "/contact" }
      ],
      additionalLinks: [
        { name: this.$t("sections.imprint"), path: "/imprint" },
        { name: this.$t("sections.privacyPolicy"), path: "/privacypolicy" }
      ]
    };
  }
};
</script>

<style scoped>
.additional-links {
  right: 0;
  display: inline;
  position: absolute;
  bottom: 0.9rem;
  right: 0;
}

@media screen and (max-width: 700px) {
  .additional-links {
    display: block;
    position: relative;
    bottom: 0;
  }
}
</style>
