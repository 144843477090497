import { render, staticRenderFns } from "./TrebleCrochetIcon.vue?vue&type=template&id=38d550f9&scoped=true&"
import script from "./TrebleCrochetIcon.vue?vue&type=script&lang=js&"
export * from "./TrebleCrochetIcon.vue?vue&type=script&lang=js&"
import style0 from "./TrebleCrochetIcon.vue?vue&type=style&index=0&id=38d550f9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d550f9",
  null
  
)

export default component.exports