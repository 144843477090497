import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import BunnyIcon from "@/components/Icons/BunnyIcon";
import YarnIcon from "@/components/Icons/YarnIcon";
import PartsIcon from "@/components/Icons/PartsIcon";
import MeasurementIcon from "@/components/Icons/MeasurementIcon";
import ClockIcon from "@/components/Icons/ClockIcon";
import ChainStitchIcon from "@/components/Icons/stitches/ChainStitchIcon";
import DoubleCrochetIcon from "@/components/Icons/stitches/DoubleCrochetIcon";
import SlipStitchIcon from "@/components/Icons/stitches/SlipStitchIcon";
import SingleCrochetIcon from "@/components/Icons/stitches/SingleCrochetIcon";
import TrebleCrochetIcon from "@/components/Icons/stitches/TrebleCrochetIcon";
import DoubleTrebleCrochetIcon from "@/components/Icons/stitches/DoubleTrebleCrochetIcon";
import HalfDoubleCrochetIcon from "@/components/Icons/stitches/HalfDoubleCrochetIcon";
import MagicRingIcon from "@/components/Icons/stitches/MagicRingIcon";
import TelegramIcon from "@/components/Icons/TelegramIcon";
import RepetitionIcon from "@/components/Icons/stitches/RepetitionIcon";

Vue.use(Vuetify);

const chartCrafterTheme = {
  primary: "#f69597",
  secondary: "#86bcb8",
  accent: "#fae5e4",
  darkaccent: "#e67778",
  error: "#FF5252",
  info: "#2196F3",
  success: "#4CAF50",
  warning: "#FFC107",
  gray: "#666666",
  black: "#535353"
};

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: chartCrafterTheme
    }
  },
  icons: {
    values: {
      bunny: {
        component: BunnyIcon
      },
      yarn: {
        component: YarnIcon
      },
      parts: {
        component: PartsIcon
      },
      measurement: {
        component: MeasurementIcon
      },
      clock: {
        component: ClockIcon
      },
      chainStitch: {
        component: ChainStitchIcon
      },
      doubleCrochet: {
        component: DoubleCrochetIcon
      },
      slipStitch: {
        component: SlipStitchIcon
      },
      singleCrochet: {
        component: SingleCrochetIcon
      },
      halfDoubleCrochet: {
        component: HalfDoubleCrochetIcon
      },
      trebleCrochet: {
        component: TrebleCrochetIcon
      },
      doubleTrebleCrochet: {
        component: DoubleTrebleCrochetIcon
      },
      magicRing: {
        component: MagicRingIcon
      },
      telegram: {
        component: TelegramIcon
      },
      repetition: {
        component: RepetitionIcon
      }
    }
  }
});
